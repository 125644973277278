<template>
    <ul>
        <li class="text" v-for="text in texts" :key="text">{{ $t(text) }}</li>
    </ul>
</template>
<script>
export default {
    data() {
        return {
            texts: ['faq.tivimate.step3.open', 'faq.tivimate.step3.search', 'faq.tivimate.step3.download']
        }
    }
}
</script>
<style scoped>
@import "../../../components/styles/guides-default-styles.css";
</style>
